exports.shouldUpdateScroll = ({ prevRouterProps, pathname }) => {
  if (pathname.indexOf("/app") === -1) {
    return true;
  }
  if (prevRouterProps) {
    const {
      location: { pathname: oldPathname }
    } = prevRouterProps;
    if (oldPathname.indexOf("/app") !== -1) {
      return false;
    }
  }
  return true;
};
