// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("/media/luuk/Data/development/afbuza/wie-bezit-onze-stad/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-app-index-tsx": () => import("/media/luuk/Data/development/afbuza/wie-bezit-onze-stad/src/pages/app/index.tsx" /* webpackChunkName: "component---src-pages-app-index-tsx" */),
  "component---src-pages-colofon-index-tsx": () => import("/media/luuk/Data/development/afbuza/wie-bezit-onze-stad/src/pages/colofon/index.tsx" /* webpackChunkName: "component---src-pages-colofon-index-tsx" */),
  "component---src-pages-disclaimer-index-tsx": () => import("/media/luuk/Data/development/afbuza/wie-bezit-onze-stad/src/pages/disclaimer/index.tsx" /* webpackChunkName: "component---src-pages-disclaimer-index-tsx" */),
  "component---src-pages-index-tsx": () => import("/media/luuk/Data/development/afbuza/wie-bezit-onze-stad/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/media/luuk/Data/development/afbuza/wie-bezit-onze-stad/.cache/data.json")

